
import { getOrders } from "../router/orders"
import { useState, useEffect } from "react"
import Navbar from "./Navbar"
import OrderView from "./OrderView"
import { Td, Tr, Th, Table} from "../styles/admin"

const statusList = ["zamówione","opłacone","spakowane","wysłane"]
const Orders = () => {
    const [orders, setOrders] = useState([])
    const [chosen, setChosen] = useState(null)
    const [isChecked, setIsChecked] = useState([true, true, true, true])
    const orderSetter = async () => {
        const data = (await getOrders()).data
        if (data){
            data.reverse()
            setOrders(data)
        }
    }
    
    useEffect(() => { orderSetter() }, [])
    
    const handleCheckboxChange = (event) => {
        const index = event.target.name;
        const newChecked = [...isChecked];
        newChecked[index] = !isChecked[index];
        setIsChecked(newChecked);
    };
    return (
        <div>
            <Navbar />
            <div>
                {statusList.map((status, index) =>
                <>
                <input name = {index} type="checkbox" checked={isChecked[index]} onChange={handleCheckboxChange}/>
                <label for={index}>{status}</label>
                <br />
                </>)
                }
            </div>
            <Table>
                <Tr>
                    <Th></Th>
                    <Th>Imię i nazwisko</Th>
                    <Th>data złożenia zamówienia</Th>
                    <Th>status zamówienia</Th>
                    <Th>numer zamówienia</Th>
                </Tr>
                {orders ? orders.filter(order => isChecked[order.status]).map((order, index) => 
                <OrderView 
                    order={order} 
                    chosen={chosen} 
                    setChosen={setChosen} 
                    index={index}
                    statusList={statusList}
                    orderSetter={orderSetter}
                />):null}
            </Table>
        </div>
    )
}
export default Orders