
import { Td, Tr, ThMin, TdMin, TrMin, ButtonOpen, Holder, TextPlace, Table } from "../styles/admin"
import { patchStatus as patchReq } from "../router/orders"
const Orders = (props) => {
    const {order, chosen, setChosen, index, statusList, orderSetter} = props
    const {address, lockerNumber, cart, email, name, phone, surname, create_time, nip_number, company_name, status, _id} = order
    const {country, city, houseNumber, street, zipCode} = address

    const time = new Date(create_time)

    const clickHandler = () => {
        if(index==chosen) setChosen(-1)
        else setChosen(index)
    }
    const patchStatus = async () => {
        await patchReq(_id, status)
        orderSetter()
    }
    console.log(cart)
    return(
        <>
        <Tr>
            <Td>
                <ButtonOpen onClick={clickHandler}>{index==chosen?"zwiń":"rozwiń"}</ButtonOpen>
            </Td>
            <Td>{name} {surname}</Td>
            <Td>{time.getDate()}.{(time.getMonth()+1).toString().padStart(2, '0')}.{time.getFullYear()} - {time.getHours()}:{time.getMinutes()} </Td>
            <Td>{statusList[status]}</Td>
            <Td>{_id}</Td>
        </Tr>
        {
            chosen==index?
            <Tr>
                <Td colSpan={5}>
                    <Holder>
                        <TextPlace>
                            imię: {name}<br />
                            nazwisko: {surname}<br />
                            e-mail: {email}<br />
                            telefon: {phone}<br />
                            kraj: {country}<br />
                            miasto: {city}<br />
                            nr. domu: {houseNumber}<br />
                            ulica: {street}<br />
                            kod pocztowy: {zipCode}<br />
                            numer paczkomatu: {lockerNumber}<br />
                            {nip_number?"NIP: " + nip_number:null}<br/>
                            {company_name?"nazwa firmy: " + company_name:null}
                        </TextPlace>
                        <TextPlace>
                            <Table>
                                <TrMin>
                                    <ThMin>nazwa</ThMin>
                                    <ThMin>cena</ThMin>
                                    <ThMin>ilość</ThMin>
                                </TrMin>
                                {cart.map(item=><TrMin>
                                    <TdMin>{item.name}</TdMin>
                                    <TdMin>{item.price.toFixed(2)} zł</TdMin>
                                    <TdMin>{item.quantity}</TdMin>
                                </TrMin>)}
                            </Table>
                            <br />
                            status: {statusList[status]}
                            <br />
                            <br />
                            {status == 1 || status == 2?
                            <ButtonOpen onClick={patchStatus}>aktualizuj status do {statusList[status + 1]}</ButtonOpen>
                            :null}
                        </TextPlace>
                    </Holder>
                </Td>
            </Tr>
            :null
        }
        </>
    )
}
export default Orders